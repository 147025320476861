import fmoClient from "./request";
import {USER_ROLE} from "../constants/user";

const getShippingService = async () => {
  return await fmoClient.get("/carrier");
};

const getCarrierAgenci = async () => {
  return await fmoClient.get("users/role/" + USER_ROLE.CARRIER)
}

export default {
  getShippingService,
  getCarrierAgenci
};
