import fmoClient from "./request";

const getCurrentUser = async () => {
  return await fmoClient.get("/users/me");
};

const getUsers = async (params) => {
  return await fmoClient.get("/users", { params });
};

const getUserById = async (userId) => {
  return await fmoClient.get(`/users/${userId}`);
};

const updateUser = async ({ userId, data }) => {
  return await fmoClient.put(`/users/${userId}`, data);
};

const createUser = async (data) => {
  return await fmoClient.post("/users", data);
};

export default {
  getCurrentUser,
  getUsers,
  getUserById,
  updateUser,
  createUser,
};
