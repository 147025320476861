import fmoClient from "./request";

const getCatalogs = async (params) => {
  return await fmoClient.get("/product-catalog", { params });
};

const createCatalog = async (data) => {
  return await fmoClient.post("/product-catalog", data);
};

const getCatalogById = async (catalogId) => {
  return await fmoClient.get(`/product-catalog/${catalogId}`);
};

const updateCatalog = async ({ catalogId, data }) => {
  return await fmoClient.put(`/product-catalog/${catalogId}`, data);
};

const deleteCatalog = async (catalogId) => {
  return await fmoClient.delete(`/product-catalog/${catalogId}`);
};

export default {
  getCatalogs,
  createCatalog,
  updateCatalog,
  getCatalogById,
  deleteCatalog,
};
