import fmoClient from "./request";

const getManufacturerById = async (id) => {
  return await fmoClient.get(`/manufacturer/${id}`);
};

const getManufacturers = async () => {
  return await fmoClient.get("/manufacturer");
};

const createManufacturer = async (data) => {
  return await fmoClient.post("/manufacturer", data);
};

const updateManufacturer = async ({ id, data }) => {
  return await fmoClient.put(`/manufacturer/${id}`, data);
};

export default {
  getManufacturerById,
  getManufacturers,
  createManufacturer,
  updateManufacturer,
};
