import fmoClient from "./request";

const getStores = async () => {
  return await fmoClient.get("/store");
};

const createStore = async (data) => {
  return await fmoClient.post("/store", data);
};

const getStoreById = async (storeId) => {
  return await fmoClient.get(`/store/${storeId}`);
};

const updateStore = async ({ storeId, data }) => {
  return await fmoClient.put(`/store/${storeId}`, data);
};

const deleteStore = async (storeId) => {
  return await fmoClient.delete(`/store/${storeId}`);
};

export default {
  getStores,
  createStore,
  updateStore,
  getStoreById,
  deleteStore,
};
