import fmoClient from "./request";

const updateProcessingStatus = async (orderId, data) => {
  return await fmoClient.put(`/orders/factory/${orderId}/update-process-status`, data);
};

const bulkUpdateProcessStatus = async (data) => {
  return await fmoClient.post("/orders/factory/bulk-update/process_status", data)
}

export default {
  updateProcessingStatus,
  bulkUpdateProcessStatus
}