import { notification } from "antd";
import axios from "axios";
import { isArray } from "lodash";

const fmoClient = axios.create({ baseURL: process.env.REACT_APP_FMO_BE_URL });

const getAuthorization = () => {
  const token = localStorage.getItem("id_token");
  return token ? `Bearer ${token}` : "";
};

// Do something before request is sent
const requestInterceptor = (request) => {
  request.headers.Authorization = getAuthorization();
  return request;
};

// Any status code that lie within the range of 2xx cause this function to trigger
const responseSuccessInterceptor = (response) => {
  // Do something with response data
  return response;
};

// Any status codes that falls outside the range of 2xx cause this function to trigger
const responseErrorInterceptor = (error) => {
  // Do something with response error
  if (isArray(error.response?.data)) {
    error.response.data.forEach((e) => {
      notification.error({
        message: "Fails",
        description: `${e.id} can not process because ${e.message}`,
      });
    });
  } else {
    notification.error({
      message: "Error",
      description: error.response?.data?.message || error.message,
    });
  }

  if (error.response?.status === 401) {
    localStorage.removeItem("id_token");
    window.location.href = "/signin";
  }
  return Promise.reject(error);
};

fmoClient.interceptors.request.use(requestInterceptor);
fmoClient.interceptors.response.use(
  responseSuccessInterceptor,
  responseErrorInterceptor
);

export default fmoClient;
