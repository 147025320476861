import fmoClient from "./request";

const getTrackingList = async (params) => {
  return await fmoClient.get("/shipping-info/tracking", { params });
};

const getTrackingSummary = async (params) => {
  return await fmoClient.get("/shipping-info/tracking/summary", {params});
};

const updateTracking = async ({ shippingId, data }) => {
  return await fmoClient.put(`/shipping-info/${shippingId}`, data);
};

const calculateShippingFee = async (data) => {
  return await fmoClient.post("/shipping-info/fee/calculate", data);
};

const exportTracking = async (params) => {
  return await fmoClient.get("/shipping-info/tracking/exports", { params });
};

const cancelTracking = async ({ shippingId }) => {
  return await fmoClient.put(`/shipping-info/tracking/cancel/${shippingId}`);
};

const importTracking = async (formData) => {
  return await fmoClient.post(`/shipping-info/tracking/imports`, formData);
};

const getShippingFee = async () => {
  return await fmoClient.get('/shipping-info/fee');
}

const updateShippingFee = async (payload) => {
  return await fmoClient.put(`shipping-info/fee/${payload.id}`, payload.data)
}

const getExchangeRate = async () => {
  return await fmoClient.get(`shipping-info/exchange-rate`)
}

const updateExchangeRate = async (rate) => {
  return await fmoClient.put(`shipping-info/exchange-rate/update`, {rate: rate})
}

const syncTrackingLionbay = async (params) => {
  return await fmoClient.post(`/shipping-info/tracking/lionbaysync`, params)
}

const importShippingFee = async (formData) => {
  return await fmoClient.post(`/shipping-info/shipping-fee/imports`, formData);
};

const pushTracking17Track = async (params) => {
  return await fmoClient.post(`/shipping-info/17track/push`, params)
}

export default {
  getTrackingList,
  getTrackingSummary,
  updateTracking,
  calculateShippingFee,
  exportTracking,
  cancelTracking,
  importTracking,
  getShippingFee,
  updateShippingFee,
  getExchangeRate,
  updateExchangeRate,
  syncTrackingLionbay,
  importShippingFee,
  pushTracking17Track
};
