import fmoClient from "./request";

const getWorkersByManufacturerId = async (manufacturerId) => {
  return await fmoClient.get(`/manufacturer/${manufacturerId}/workers`);
};

const deleteWorker = async (workerId) => {
  return await fmoClient.delete(`/worker/${workerId}`);
};

const createWorker = async (data) => {
  return await fmoClient.post("/worker", data);
};

export default {
  createWorker,
  deleteWorker,
  getWorkersByManufacturerId,
};
