export const ORDER_STATUS = {
  DESIGN_NOT_CONFIRM: "DESIGN NOT CONFIRM",
  ON_HOLD: "ON HOLD",
  DESIGN_CONFIRMED: "DESIGN CONFIRMED",
  PRIORITY: "PRIORITY",
  PROCESSING: "PROCESSING",
  PENDING_APPROVAL: "PENDING APPROVAL",
  APPROVED: "APPROVED",
  SHIPPED: "SHIPPED",
  CANCEL: "CANCEL",
  AFTER_SALES: "AFTER SALES",
};

export const AFTER_SALES_TYPES = {
  REFUND: 'REFUND',
  RESEND: 'RESEND',
  WARRANTY: 'WARRANTY',
  OTHER: 'OTHER'
}

export const ORDER_STATUS_TRANSITION = {
  [ORDER_STATUS.DESIGN_NOT_CONFIRM]: [ORDER_STATUS.ON_HOLD, ORDER_STATUS.DESIGN_CONFIRMED],
  [ORDER_STATUS.ON_HOLD]: [ORDER_STATUS.DESIGN_CONFIRMED],
  [ORDER_STATUS.DESIGN_CONFIRMED]: [ORDER_STATUS.PROCESSING, ORDER_STATUS.PRIORITY],
  [ORDER_STATUS.PROCESSING]: [ORDER_STATUS.PENDING_APPROVAL, ORDER_STATUS.PRIORITY],
  [ORDER_STATUS.PENDING_APPROVAL]: [ORDER_STATUS.APPROVED],
  [ORDER_STATUS.APPROVED]: [ORDER_STATUS.SHIPPED],
  [ORDER_STATUS.SHIPPED]: [ORDER_STATUS.AFTER_SALES],
  [ORDER_STATUS.CANCEL]: [],
  [ORDER_STATUS.PRIORITY]: [ORDER_STATUS.PENDING_APPROVAL],
};

export const ORDER_STATUS_COLOR = {
  [ORDER_STATUS.DESIGN_NOT_CONFIRM]: "#ffdb00",
  [ORDER_STATUS.ON_HOLD]: "#14f3ce",
  [ORDER_STATUS.DESIGN_CONFIRMED]: "#00daff",
  [ORDER_STATUS.PROCESSING]: "#107fff",
  [ORDER_STATUS.PENDING_APPROVAL]: "#ff7119",
  [ORDER_STATUS.APPROVED]: "#1142ee",
  [ORDER_STATUS.SHIPPED]: "#00b600",
  [ORDER_STATUS.CANCEL]: "#4d4d4d",
  [ORDER_STATUS.PRIORITY]: "#48ff48",
  [ORDER_STATUS.AFTER_SALES]: "#d258f1"
};
