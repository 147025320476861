import actions from "./actions";

const initState = { idToken: null, userData: null };

export default function authReducer(state = initState, action) {
  switch (action.type) {
    case actions.LOGIN_SUCCESS:
      return {
        ...state,
        idToken: action.token,
        userData: {
          ...state.userData,
          ...action.userData,
        },
      };
    case actions.LOGOUT:
      return initState;
    case actions.GET_USER_INFORMATION_SUCCESS:
      return {
        ...state,
        userData: {
          ...state.userData,
          ...action.userData,
        },
      };
    default:
      return state;
  }
}
