import fmoClient from "./request";

const getStockCatalogs = async (params) => {
	return await fmoClient.get("/stock/catalogs", { params });
};

const createStockCatalogs = async (data) => {
	return await fmoClient.post("/stock/catalogs", data);
};

const getStockCatalogById = async (catalogId) => {
	return await fmoClient.get(`/stock/catalogs/${catalogId}`);
};

const updateStockCatalog = async (payload) => {
	return await fmoClient.put(`/stock/catalogs/${payload.catalogId}`, payload.data);
}

export default {
	getStockCatalogs,
	createStockCatalogs,
	getStockCatalogById,
	updateStockCatalog
}