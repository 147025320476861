import fmoClient from "./request"

const getMaterials = async (params) => {
	return await fmoClient.get('/materials-manager', { params } );
};

const importMaterial = async (formData) => {
	return await fmoClient.post('/materials-manager/upload', formData);
};

const truncateMaterials = async () => {
	return await fmoClient.delete('materials-manager/truncate')
}

const exportMaterials = async (params) => {
	return await fmoClient.get('materials-manager/export/reports', { params })
}

export default {
	getMaterials,
	importMaterial,
	truncateMaterials,
	exportMaterials,
}