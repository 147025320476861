import fmoClient from "./request";
const getStockOrders = async (params) => {
	return await fmoClient.get("/stock/orders/stock_orders", {params});
}

const getStockOrderById = async (id) => {
	return await fmoClient.get(`/stock/orders/stock_orders/${id}`);
}

const getStockOrderSummary = async (params) => {
	return await fmoClient.get("/stock/orders/stock_orders/summary", {params});
}

const createStockOrder = async (data) => {
	return await fmoClient.post("/stock/orders/stock_orders", data);
};

const updateStockOrder = async ({id, data}) => {
	return await fmoClient.put(`/stock/orders/stock_orders/${id}`, {data});
}

const updateStockOrderStatus = async (id, data) => {
	return await fmoClient.put(`/stock/orders/stock_orders/${id}/update-status`, data);
}

export default {
	getStockOrders,
	getStockOrderSummary,
	createStockOrder,
	getStockOrderById,
	updateStockOrder,
	updateStockOrderStatus,
}