import fmoClient from "./request";

const createRefundOrder = async (data) => {
	return await fmoClient.post("/refund", data)
}

const getRefundOrders = async (params) => {
	return await fmoClient.get("/refund", {params})
}

const updateRefundStatus = async (data) => {
	return await fmoClient.put("/refund", data)
}

export default {
	createRefundOrder,
	getRefundOrders,
	updateRefundStatus,
}